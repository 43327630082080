import { fetchJSON } from './common/fetch';
import { transform } from './transform';

const durationTolerance = 2;

export const fetchVideoList = (config, callback) => {
  const {
    title,
    topic,
    channel,
    limit = 0,
    everywhere = false,
    future = true,
    sortOrder = 'desc',
    sortBy = 'timestamp',
    filters = [],
    transforms = [],
    reverse,
  } = config;

  const query = JSON.stringify({
    queries: [
      title && {
        fields: ['title'],
        query: title,
      },
      topic && {
        fields: ['topic'],
        query: topic,
      },
      channel && {
        fields: ['channel'],
        query: channel,
      },
    ].filter(query => !!query),
    future,
    offset: 0,
    sortBy: 'timestamp',
    size: limit * 4 || 100,
    sortOrder,
    everywhere,
    ...(config.durationMin && {
      duration_min: config.durationMin * 60,
    }),
    ...(config.durationMax && {
      duration_max: config.durationMax * 60,
    }),
    ...(config.duration && {
      duration_min: (config.duration - durationTolerance) * 60,
      duration_max: (config.duration + durationTolerance) * 60,
    }),
  });

  const request = new XMLHttpRequest();

  request.open('POST', 'https://mediathekviewweb.de/api/query');

  request.addEventListener('load', () => {
    try {
      const response = JSON.parse(request.responseText);

      callback(
        transform(response.result.results, {
          filters,
          transforms,
          limit,
          sortBy,
          reverse,
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  });

  request.send(query);
};

export const fetchMemoList = (callback, user) => {
  if (user) {
    fetchJSON(`/data/${user}.json?${new Date().getTime()}`, true).then(data => {
      // eslint-disable-next-line n/no-callback-literal
      callback(data?.data || []);
    });
  } else {
    // eslint-disable-next-line n/no-callback-literal
    callback([]);
  }
};

export const postMemoList = (data, user) => {
  if (user) {
    const request = new XMLHttpRequest();

    request.open('POST', '/data.php');
    request.send(JSON.stringify({ data, user }));
  }
};
