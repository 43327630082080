import PropTypes from 'prop-types';

export const Show = ({ show }) => (
  <option
    value={show.id}
  >
    {show.display || show.topic}
    {show.topic && show.title && ` - ${show.title}`}
  </option>
);

Show.propTypes = {
  show: PropTypes.shape({
    topic: PropTypes.string.isRequired,
    display: PropTypes.string,
    title: PropTypes.string,
  }),
};
