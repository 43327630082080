import { getStoredWatchList } from './common/video';

const getLeadingNumber = string => Number.parseInt(string.split('.')[0], 10);

const sortFunctions = {
  title: ({ title: aTitle }, { title: bTitle }) => {
    let aValue = aTitle;

    let bValue = bTitle;

    const aNumber = getLeadingNumber(aTitle);
    const bNumber = getLeadingNumber(bTitle);

    if (aNumber > -1 && bNumber > -1) {
      aValue = aNumber;
      bValue = bNumber;
    }
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  },
  season: ({ title: aTitle }, { title: bTitle }) => {
    const aValue = aTitle.match(/\(.*\/.*\)/);
    const bValue = bTitle.match(/\(.*\/.*\)/);

    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  },
};

const filterFunctions = {
  general: ({ title, description }) =>
    !(
      title.includes('Trailer') ||
      title.includes('Hörfassung') ||
      title.includes('Gebärdensprache') ||
      title.includes('Audiodeskription') ||
      title.includes('Ukrainisch') ||
      description.includes('и') ||
      description.includes('ł') ||
      description.includes('ě') ||
      description.includes('č') ||
      description.includes('š')
    ),
  custom: filters => item =>
    !filters
      .map(filter => {
        return filter.match.test(item[filter.property]);
      })
      .includes(false),
};

export const transform = (items, { filters, transforms, limit, sortBy, reverse }) => {
  const data = items
    .filter(filterFunctions.general)
    .filter(filterFunctions.custom(filters))
    .splice(0, limit || items.length)
    .map(item => {
      for (const transform of transforms) {
        const match = transform.match || item[transform.property];
        const replace = item[transform.replace] || transform.replace;

        item = {
          ...item,
          [transform.property]: item[transform.property].replace(match, replace),
        };
      }
      return item;
    })
    .sort(sortFunctions[sortBy]);

  return addWatchData(reverse ? data.reverse() : data);
};

export const addWatchData = items => {
  const videoWatchList = getStoredWatchList();

  return items.map(item => ({
    ...item,
    currentTime: videoWatchList.find(watchedItem => watchedItem.id === item.id)?.currentTime || 0,
    watched: !!videoWatchList.find(watchedItem => watchedItem.id === item.id)?.watched,
  }));
};
