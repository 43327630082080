import { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { scrollTo } from '../helpers/common/screen';
import { getStoredWatchList, handleFullscreenChange } from '../helpers/common/video';
import { getCategoriesWithShows, getConfig } from '../helpers/config';
import { fetchMemoList, fetchVideoList, postMemoList } from '../helpers/request';
import { Category } from './Category';
import { Video } from './Video';
import { Image } from './common/Image';

const useParams = () => {
  const params = new URLSearchParams(useLocation().search);
  const user = params.get('u');
  const selection = params.get('s');
  const quality = params.get('q'); // hd, low
  const showPreview = !!params.get('p');
  const showImage = !!params.get('i');
  const findImage = !!params.get('f');
  const uploadWatchedItems = !!params.get('uwi');

  return {
    params,
    user,
    selection,
    quality,
    showPreview,
    showImage,
    findImage,
    uploadWatchedItems,
  };
};

export const Tv = () => {
  const {
    params,
    user,
    selection,
    quality,
    showPreview,
    showImage,
    findImage,
    uploadWatchedItems,
  } = useParams();

  const [selectedConfig, setSelectedConfig] = useState(getConfig(selection));
  const [videosLibrary, setVideosLibrary] = useState([]);
  const [videosProfile, setVideosProfile] = useState([]);
  const [videosMerged, setVideosMerged] = useState([]);
  const [adultMode, setAdultMode] = useState(false);
  const select = useRef();
  const videoRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    fetchMemoList(setVideosProfile, user);
  }, [
    user,
  ]);

  useEffect(() => {
    setSelectedConfig(getConfig(selection));
  }, [
    selection,
  ]);

  useEffect(() => {
    videoRefs.current = [];
    setVideosLibrary([]);
    fetchVideoList(selectedConfig, setVideosLibrary);
  }, [
    selectedConfig,
  ]);

  useEffect(() => {
    setVideosMerged(
      videosLibrary.map(fetchedItem => ({
        ...fetchedItem,
        ...(videosProfile.find(memoItem => memoItem.id === fetchedItem.id) || {}),
      })),
    );
  }, [videosLibrary, videosProfile]);

  useEffect(() => {
    if (uploadWatchedItems) {
      postMemoList(getStoredWatchList().filter(item => item.watched === true), user);
    }
  }, [
    user,
    uploadWatchedItems,
  ]);

  useEffect(() => {
    scrollTo('.video.unwatched', '.videos');
    scrollTo('.video.watching', '.videos');
  }, [
    videosMerged,
  ]);

  useEffect(() => {
    // search for images for tv shows on duckduckgo
    findImage &&
      window.open(
        [
          'https://duckduckgo.com/?q=',
          `${encodeURIComponent(selectedConfig.topic)}+${encodeURIComponent(selectedConfig.channel)}`,
          '&atb=v295-1&iar=images&iaf=layout%3AWide%2Csize%3AWallpaper&iax=images&ia=images',
        ].join(''),
      );
  }, [
    selectedConfig,
    findImage,
  ]);

  const navigateToSelection = useCallback(() => {
    params.set('s', select.current.value);
    navigate({ search: params.toString() });
  }, [params, navigate]);

  const handleChangeConfig = () => {
    navigateToSelection();
  };

  useEffect(() => {
    navigateToSelection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adultMode]);

  return (
    <div className="main">
      {showImage && (
        <div
          className="background"
          style={{
            backgroundImage: `url("/img/${selectedConfig.id}.jpg")`,
          }}
        />
      )}
      <form className="form header">
        <select
          className="select form-select"
          value={selectedConfig.id}
          ref={select}
          onChange={handleChangeConfig}
        >
          {getCategoriesWithShows()
            .filter(category => adultMode ? category.id !== 'kids' : category.id === 'kids')
            .map((category, index) =>
              <Category key={category.id} category={category} />,
            )}
        </select>
      </form>
      <div className="videos">
        {videosMerged.map((video, index) => {
          video.quality = quality;
          video.url = video[`url_video_${quality}`];

          if (!video.url) {
            video.quality = 'sd';
            video.url = video.url_video;
          }

          if (!video.url) {
            video.quality = 'low';
            video.url = video.url_video_low;
          }

          const downloadTitle = (selectedConfig.downloadTitle || ['topic', 'title'])
            .map(item => video[item])
            .join(' - ');

          return (
            <Video
              key={video.id}
              user={user}
              video={{
                ...video,
                index,
                // fix wrongly double encoded ß
                url: video.url.replace('%C3%83%C5%B8', '%c3%9f'),
                quality: video.quality.toUpperCase(),
                showPreview,
                downloadTitle,
              }}
              videoRefs={videoRefs}
              showImage={showImage}
              configId={selectedConfig.id}
            />
          );
        })}
      </div>
      <button
        type="button"
        className="profile-switch"
        onClick={() => {
          setAdultMode(!adultMode);
        }}
      >
        <Image src="/favicon.ico" alt="head" />
      </button>
    </div>
  );
};
