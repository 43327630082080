import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import './App.css';
import { Tv } from './components/Tv';

const App = () => (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Tv />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
