import PropTypes from 'prop-types';

import { Show } from './Show';

export const Category = ({ category }) => category.shows.map(show => (
  <Show key={show.id} show={show} />
));

Category.propTypes = {
  category: PropTypes.shape({
    shows: PropTypes.array.isRequired,
  }),
};
