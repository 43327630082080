import fetchProgress from 'fetch-progress';

const forceDownload = (blob, filename) => {
  const a = document.createElement('a');

  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
};

// Current blob size limit is around 500MB for browsers
export const downloadResource = (url, filename, callback) => {
  if (!filename) {
    filename = url.split('\\').pop().split('/').pop();
  }

  fetch(url, {
    headers: new Headers({
      Origin: document.location.origin,
    }),
    mode: 'cors',
  })
    .then(
      fetchProgress({
        // implement onProgress method
        onProgress(progress) {
          // eslint-disable-next-line no-console
          // console.log({ progress });
          callback(progress.percentage);
          // A possible progress report you will get
          // {
          //    total: 3333,
          //    transferred: 3333,
          //    speed: 3333,
          //    eta: 33,
          //    percentage: 33
          //    remaining: 3333,
          // }
        },
      }),
    )
    .then(response => response.blob())
    .then(blob => {
      const blobUrl = window.URL.createObjectURL(blob);

      forceDownload(blobUrl, filename);
    })
    // eslint-disable-next-line no-console
    .catch(e => console.error(e));
};
