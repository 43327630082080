import { captureKeys } from './control';
import { storage } from './storage';

export const setStoredWatchList = items => storage.set('video:watchList', items);

export const getStoredWatchList = () => storage.get('video:watchList') || [];

export const getStoredWatchListFiltered = itemId => getStoredWatchList()
  .filter(item => item.id !== itemId);

export const handleFullscreenChange = e => {
  // document.fullscreenElement will point to the element that
  // is in fullscreen mode if there is one. If there isn't one,
  // the value of the property is null.
  const video = document.fullscreenElement;
  const handleKeydown = e => {
    captureKeys(e, video);
  };

  if (video) {
    document.addEventListener('keydown', handleKeydown);
  } else {
    e.target?.pause?.();
    document.removeEventListener('keydown', handleKeydown);
  }
};
