export const categories = {
  kids: 'Kinder',
  animals: 'Tiere',
  fun: 'Unterhaltung',
  health: 'Gesundheit',
  people: 'Leute',
  hobby: 'Hobby',
  science: 'Wissenschaft',
  undefined: 'Keine Kategorie',
};
