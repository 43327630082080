import classNames from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import { getTimestamp } from '../helpers/common/date';
import { getRandom } from '../helpers/common/math';
import { getStoredWatchListFiltered, setStoredWatchList } from '../helpers/common/video';
import { downloadResource } from '../helpers/download';
import { postMemoList } from '../helpers/request';
import { File } from './common/File';
import { Link } from './common/Link';

export const Video = ({
  user,
  video,
  videoRefs,
  showImage,
  configId,
}) => {
  const [color] = useState(`rgba(${getRandom(127, 255)}, ${getRandom(127, 255)}, ${getRandom(127, 255)}, 90%)`);
  const [showVideo, setShowVideo] = useState(video.showPreview || !!video.currentTime);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(!!video.currentTime);
  const [isVideoWatched, setIsVideoWatched] = useState(!!video.watched);
  const timerRef = useRef();

  const playVideoFullscreen = currentVideo => {
    for (const item of videoRefs.current) {
      if (item !== currentVideo) {
        item?.pause?.();
      }
    }
    try {
      currentVideo.requestFullscreen?.();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const storeVideoDataDelayed = (video, delay) => {
    if (!(delay && timerRef.current)) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        postMemoList([video], user);
        timerRef.current = null;
      }, delay || 0);
    }
    setStoredWatchList([
      video,
      ...getStoredWatchListFiltered(video.id),
    ]);
  };

  return (
    <div
      className={classNames(
        'video',
        isVideoWatched ? 'watched' : 'unwatched',
        isVideoPlaying && 'watching',
      )}
      style={{
        backgroundColor: !(isVideoWatched || isVideoPlaying) && color,
        backgroundSize: `${downloadProgress}% 100%`,
      }}
    >
      {showVideo ? (
        <video
          controls
          src={video.url}
          ref={element => {
            videoRefs.current[video.index] = element;
          }}
          onPlay={e => {
            setIsVideoPlaying(true);
            playVideoFullscreen(e.target);
          }}
          onPause={() => {
            // console.log(getVideoCurrentTimeList());
          }}
          onEnded={() => {
            setIsVideoPlaying(false);
            setIsVideoWatched(true);
            storeVideoDataDelayed({
              ...video,
              currentTime: 0,
              watched: true,
              updated: getTimestamp(),
            });
            document?.exitFullscreen?.();
          }}
          onLoadedMetadata={e => {
            if (video.showPreview || !!video.currentTime) {
              e.target.currentTime += video.currentTime || 1;
            } else {
              e.target.currentTime += video.currentTime;
              e.target.play();
            }
          }}
          onTimeUpdate={e => {
            const watched = (e.currentTarget.duration - e.currentTarget.currentTime) <= 3;
            const currentTime = watched ? 0 : e.currentTarget.currentTime;

            storeVideoDataDelayed({
              ...video,
              currentTime,
              watched,
              updated: getTimestamp(),
            }, 1000);
          }}
        />
      ) : (
        <File
          className="details"
          style={{
            backgroundImage: showImage ? `url('/img/${configId}.jpg')` : 'none',
          }}
          path={video.url}
          onClick={() => {
            setShowVideo(true);
          }}
        >
          <span>{video.title}</span>
          <hr />
          <span
            dangerouslySetInnerHTML={{
              __html: video.description.replace('\n.....', '&hellip;').replace('\n', '<hr />') || video.topic,
            }}
          />
        </File>
      )}
      <div className="info">
        <span>
          {video.title}
        </span>
        <hr />
        <span>
          {format(new Date((video.timestamp || Number(video.filmlisteTimestamp)) * 1000), 'dd.MM.yyyy HH:mm')}
          &nbsp;
          {`(${Math.round(video.duration / 60)} min, ${video.quality})`}
        </span>
        <hr />
        <span>
          <File
            path={video.url}
            // don't start downloading again if there's progress already
            onClick={
              downloadProgress
                ? null
                : () => {
                  downloadResource(video.url, `${video.downloadTitle} (${video.quality}).mp4`, setDownloadProgress);
                }
            }
          >
            Download
          </File>
          &nbsp;|&nbsp;
          <Link
            to={video.url_website}
          >
            Website
          </Link>
        </span>
      </div>
    </div>
  );
};

Video.propTypes = {
  user: PropTypes.string,
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url_website: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    timestamp: PropTypes.number.isRequired,
    filmlisteTimestamp: PropTypes.string,
    index: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    quality: PropTypes.string.isRequired,
    downloadTitle: PropTypes.string.isRequired,
    currentTime: PropTypes.number.isRequired,
    watched: PropTypes.bool.isRequired,
    showPreview: PropTypes.bool,
    style: PropTypes.object,
  }),
  configId: PropTypes.string.isRequired,
  videoRefs: PropTypes.shape({
    current: PropTypes.array,
  }),
  showImage: PropTypes.bool,
};
